import React, { useState, useEffect } from 'react'
import Layout from '../layouts/defaultLayout'
import { withTranslation } from 'react-i18next'
import birdAvatar from '../assets/images/bird-avatar.png'
import { Typography, Grid } from '@material-ui/core'
import Links from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { styles } from '../pagesStyles/categoryListing'
import ProductsSort from '../components/productsSort'
import CHRSectionContainer from './../components/sectionContainer'
import CHRArrowButton from './../components/arrowButton'
import { graphql } from 'gatsby'
import axios from '../axios'
import { ENDPOINTS, ROUTES } from '../constants'
import Spinner from '../components/spinner'
import NotFound from './404'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import Card from '../components/trendingCard'
import ItemsPerPageFilter from '../components/ItemsPerPageFilter'
import { useAppState } from '../appState'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link, navigate } from 'gatsby'
import MiddleSection from '../components/homeSections/homeMiddleSection'
import chirpyestProImg from '../assets/images/chirpyest-pro.jpg'
import SEO from '../components/seo'

const PAGE_SIZE = 100

const CategoryListingPage = ({ t, categoryName, data }) => {
  const classes = styles()

  const { search } = useLocation()
  const { page, size, sort } = parse(search)

  const [appState] = useAppState()

  const [category, setCategory] = useState()
  const [loading, setLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState(Number(page) || 1)
  const [pageSize, setPageSize] = useState(Number(size) || PAGE_SIZE)
  const [sorting, setSorting] = useState(sort || 'trending')
  const [productsCount, setProductsCount] = useState(null)
  const [products, setProducts] = useState([])
  const [readMore, setReadMore] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [topProducts, setTopProducts] = useState([])
  const [error, setError] = useState('')

  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const [isCopied, setIsCopied] = useState(false)
  const [randomPosition, setRandomPosition] = useState(null)

  const MAX_TEXT_LENGTH = isPhone ? 75 : isTablet ? 150 : 234 // to ensure that text lines match the design and show read more button

  const sortingOptions = {
    trending: {
      value: 'sort=trending',
      type: 'trending',
      label: t('memberHome.trending'),
    },
    highestToLowest: {
      value: 'sort=price&dir=desc',
      type: 'highestToLowest',
      label: t('shared.highestToLowest'),
    },
    lowestToHighest: {
      value: 'sort=price&dir=asc',
      type: 'lowestToHighest',
      label: t('shared.lowestToHighest'),
    },
  }

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setProducts(items => {
      const i = items.findIndex(e => e.product_id === productId)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          shoppingBoardId,
        },
        ...items.slice(i + 1),
      ]
    })
  }

  const removeProduct = (productId: string) => {
    setProducts(items => {
      return items.filter(e => e.product_id !== productId)
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: {
            data: { category },
          },
        } = await axios.get(
          ENDPOINTS.categoryByName.replace(':categoryName', categoryName)
        )

        if (category.name) {
          setCategory(category)
          setReadMore(category.description?.length >= MAX_TEXT_LENGTH)
        } else {
          setCategory(null)
        }

        setLoading(false)
      } catch (err) {
        setLoading(false)
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [categoryName])

  useEffect(() => {
    ;(async () => {
      try {
        const initPage = Number(page) || 1
        const initSort = sort || 'trending'
        const initSize = Number(size) || PAGE_SIZE

        setPageNumber(initPage)
        setSorting(initSort)
        setPageSize(initSize)
        setLoadingProducts(true)

        const trendingProducts = []
        const topCategoryProducts = (
          await axios.get(
            ENDPOINTS.categoryTopProducts.replace(':categoryPage', categoryName)
          )
        ).data.data.map(({ data }: any) => data)

        if (initSort === 'trending') {
          trendingProducts.push(...topCategoryProducts)
        }

        const cutFrom = Math.max(
          (initPage - 1) * initSize -
            (trendingProducts.length > initSize ? 2 : 0),
          0
        )
        const cutTo = initPage * initSize
        const totalCut = Math.min(
          cutTo - cutFrom,
          Math.max(0, trendingProducts.length - cutFrom)
        )

        const reqSize = Math.max(
          (initPage === 1 ? initSize - 2 : initSize) - totalCut,
          0
        )
        const reqOffset =
          pageNumber === 1
            ? 0
            : Math.max(initSize * (initPage - 1) - 2 - topProducts.length, 0)

        const endpoint = `${ENDPOINTS.categoryListing.replace(
          ':categoryName',
          categoryName
        )}?size=${reqSize}&offset=${reqOffset}&${sortingOptions[sorting].value}`
        const res = await axios.get(endpoint)

        setTopProducts(trendingProducts)
        setProducts([
          ...trendingProducts.slice(
            cutFrom,
            pageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
          ),
          ...res.data.data.results,
        ])

        let randomNumber = Math.floor(Math.random() * 15 + 8)
        const edgePositions = [11, 15, 19]
        edgePositions.includes(randomNumber) &&
          (randomNumber = randomNumber - 1)
        setRandomPosition(randomNumber)
        setProductsCount(res.data.data.total)
        setLoadingProducts(false)
      } catch (err) {
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [categoryName])

  const fetchNextPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)

      const cutFrom = Math.max(
        pageNumber * pageSize - (topProducts.length > pageSize ? 2 : 0),
        0
      )
      const cutTo = (pageNumber + 1) * pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const reqOffset = Math.max(
        pageSize * pageNumber - 2 - topProducts.length,
        0
      )
      const reqSize = Math.max(
        (pageSize === 1 ? pageSize - 2 : pageSize) - totalCut,
        0
      )
      const endpoint = `${ENDPOINTS.categoryListing.replace(
        ':categoryName',
        categoryName
      )}?size=${reqSize}&offset=${reqOffset}&${sortingOptions[sorting].value}`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber + 1}&size=${pageSize}`
      )

      const res = await axios.get(endpoint)

      setProducts([
        ...topProducts.slice(
          cutFrom,
          pageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
        ),
        ...res.data.data.results,
      ])
      setPageNumber((p: number) => p + 1)
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const fetchPreviousPage = async () => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)

      const cutFrom = Math.max(
        (pageNumber - 2) * pageSize - (topProducts.length > pageSize ? 2 : 0),
        0
      )
      const cutTo = (pageNumber - 1) * pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const offset =
        pageNumber - 1 === 1
          ? 0
          : Math.max(pageSize * (pageNumber - 2) - 2 - topProducts.length, 0)
      const reqSize = Math.max(
        (pageNumber - 1 === 1 ? pageSize - 2 : pageSize) - totalCut,
        0
      )
      const endpoint = `${ENDPOINTS.categoryListing.replace(
        ':categoryName',
        categoryName
      )}?size=${reqSize}&offset=${offset}&${sortingOptions[sorting].value}`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber - 1}&size=${pageSize}`
      )

      const res = await axios.get(endpoint)

      setProducts([
        ...topProducts.slice(
          cutFrom,
          pageNumber - 1 === 1 && !reqSize ? cutTo - 2 : cutTo
        ),
        ...res.data.data.results,
      ])
      setPageNumber((p: number) => p - 1)
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleSorting = async ({ type, value }) => {
    try {
      setLoadingProducts(true)
      setSorting(type)

      const trendingProducts = []

      if (type === 'trending') {
        const topCategoryProducts = (
          await axios.get(
            ENDPOINTS.categoryTopProducts.replace(':categoryPage', categoryName)
          )
        ).data.data.map(({ data }: any) => data)
        setTopProducts(topCategoryProducts)
        trendingProducts.push(...topCategoryProducts)
      } else {
        setTopProducts([])
      }

      const cutFrom = 0
      const cutTo = pageSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, trendingProducts.length - cutFrom)
      )
      const reqSize = Math.max(pageSize - 2 - totalCut, 0)
      const reqOffset = 0
      const endpoint = `${ENDPOINTS.categoryListing.replace(
        ':categoryName',
        categoryName
      )}?size=${reqSize}&offset=${reqOffset}&${value}`
      const res = await axios.get(endpoint)

      setProducts([
        ...trendingProducts.slice(cutFrom, !reqSize ? cutTo - 2 : cutTo),
        ...res.data.data.results,
      ])
      setPageNumber(1)
      setLoadingProducts(false)
      window.history.pushState('', '', `?sort=${type}&page=1&size=${pageSize}`)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handlePageSizeChange = async (newSize: number) => {
    try {
      const totalSkippedItems = pageNumber * pageSize
      const newPageNumber = Math.ceil(totalSkippedItems / newSize)

      setLoadingProducts(true)
      setPageNumber(newPageNumber)
      setPageSize(newSize)

      const cutFrom = Math.max(
        (newPageNumber - 1) * newSize - (topProducts.length > newSize ? 2 : 0),
        0
      )
      const cutTo = newPageNumber * newSize
      const totalCut = Math.min(
        cutTo - cutFrom,
        Math.max(0, topProducts.length - cutFrom)
      )
      const offset =
        pageNumber === 1
          ? 0
          : Math.max(newSize * (newPageNumber - 1) - 2 - topProducts.length, 0)
      const reqSize = Math.max(
        (newPageNumber === 1 ? newSize - 2 : newSize) - totalCut,
        0
      )
      const endpoint = `${ENDPOINTS.categoryListing.replace(
        ':categoryName',
        categoryName
      )}?size=${reqSize}&offset=${offset}&${sortingOptions[sorting].value}`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${newPageNumber}&size=${newSize}`
      )

      const res = await axios.get(endpoint)

      setProducts([
        ...topProducts.slice(
          cutFrom,
          newPageNumber === 1 && !reqSize ? cutTo - 2 : cutTo
        ),
        ...res.data.data.results,
      ])
      setLoadingProducts(false)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleCopyLink = () => {
    const joinLink = `${process.env.GATSBY_SITE_URL}/join/${appState.userProfile.userName}-${appState.userId}`
    navigator.clipboard.writeText(joinLink)
    setIsCopied(true)
  }

  if (loading) {
    return <Spinner />
  }

  if (error || !category) {
    return <NotFound error={error} />
  }

  const isReadMore = category.description?.length >= MAX_TEXT_LENGTH

  return (
    <Layout>
      {/*Heading*/}
      <SEO
        title={
          categoryName.includes('_')
            ? t('seo.category.titleSub').replace(
                '[Category]',
                category.name.charAt(0).toUpperCase() + category.name.slice(1)
              )
            : t('seo.category.title').replace(
                '[Category]',
                category.name.charAt(0).toUpperCase() + category.name.slice(1)
              )
        }
        description={
          categoryName.includes('_')
            ? t('seo.category.descriptionSub').replace(
                '[Category]',
                category.name.charAt(0).toUpperCase() + category.name.slice(1)
              )
            : t('seo.category.description').replace(
                '[Category]',
                category.name.charAt(0).toUpperCase() + category.name.slice(1)
              )
        }
      />

      <div className={classes.headingWrapper}>
        <div className={classes.headingContainer}>
          <div className={classes.headingTextContainer}>
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Typography color="textPrimary">
                {category.name.toLowerCase()}
              </Typography>
            </Breadcrumbs>
            <div className={classes.headingLogoContainer}>
              <img
                className={classes.headingLogoImage}
                width={100}
                height={100}
                src={category.logo || birdAvatar}
                alt="logo"
              />
              <div className={classes.headingLogoText}>
                {/* <Typography
                  component="h1"
                  className={classes.categoryName}
                  style={{ marginBottom: 0 }}
                  variant="h1"
                >
                  {category.name}
                </Typography> */}
                <span
                  className={classes.categoryName}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {category.name}
                </span>
                <Typography
                  component="h1"
                  variant="h1"
                  className={classes.seoHeading}
                >
                  {categoryName.includes('_')
                    ? t('seo.category.h1Sub').replace(
                        '[Category]',
                        category.name.charAt(0).toUpperCase() +
                          category.name.slice(1)
                      )
                    : t('seo.category.h1').replace(
                        '[Category]',
                        category.name.charAt(0).toUpperCase() +
                          category.name.slice(1)
                      )}
                </Typography>
                <Typography
                  component="p"
                  className={classes.categoryDescription}
                  variant="subtitle2"
                >
                  {isReadMore ? (
                    readMore ? (
                      <>
                        {category.description?.slice(0, MAX_TEXT_LENGTH)}...{' '}
                        <a
                          className={classes.readMore}
                          onClick={() => setReadMore(false)}
                        >
                          read more
                        </a>
                      </>
                    ) : (
                      <>
                        {category.description}...{' '}
                        <a
                          className={classes.readMore}
                          onClick={() => setReadMore(true)}
                        >
                          read less
                        </a>
                      </>
                    )
                  ) : (
                    category.description
                  )}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.actionButtonsContainer}>
            <div className={classes.filtersContainer}>
              <div className={classes.filterButton}>
                <ProductsSort
                  label={t('shared.sortBy')}
                  onFilterChange={handleSorting}
                  selectedFilter={sortingOptions[sorting]}
                  options={Object.values(sortingOptions)}
                />
              </div>
              {/* <div className={classes.filterButton}>
                <ProductsSort
                  label={t('shared.refine')}
                  onFilterChange={() => console.log('filter')}
                  selectedFilter=""
                  options={[]}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*products list*/}
      <div>
        <div className={classes.productsContainer}>
          <div className={classes.countingFilterContainer}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item lg={3} md={4} sm={4} xs={6}>
                <Typography
                  component="p"
                  className={classes.itemsCount}
                  variant="body1"
                >
                  {productsCount}
                  {productsCount >= 10000 ? '+' : ''} {t('shared.items')}
                </Typography>
              </Grid>
              <Grid
                item
                lg={3}
                md={4}
                sm={4}
                xs={6}
                style={{
                  backgroundColor: '#FAFBFD',
                  padding: 0,
                }}
              >
                <ItemsPerPageFilter
                  onFilterChange={handlePageSizeChange}
                  selectedFilter={pageSize}
                />
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            alignItems="stretch"
            spacing={2}
            style={{ minHeight: '5vh' }}
          >
            {loadingProducts ? (
              <Spinner />
            ) : (
              products.map((product, index) => {
                if (index === randomPosition && pageNumber === 1) {
                  return (
                    <>
                      <Grid item lg={6} md={8} sm={12} xs={12}>
                        <div className={classes.joinCard}>
                          <Typography variant="h4" style={{ letterSpacing: 0 }}>
                            join the chirpyest community, share your favorites
                            with friends, earn $ when they buy.
                          </Typography>
                          <Grid container justifyContent="center">
                            {!appState.userId ? (
                              <Link
                                className={classes.joinButton}
                                target="_blank"
                                to={ROUTES.join}
                              >
                                <Typography variant="button">
                                  Join us
                                </Typography>
                              </Link>
                            ) : (
                              <div
                                className={classes.joinButton}
                                onClick={handleCopyLink}
                              >
                                <Typography variant="button">
                                  {isCopied ? 'copied' : 'copy join link'}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={4}
                        xs={6}
                        key={product.product_id}
                      >
                        <Card
                          updateProducts={updateProducts}
                          onRemove={removeProduct}
                          userId={appState.userId}
                          productInfo={product}
                          shareable
                        />
                      </Grid>
                    </>
                  )
                }
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={4}
                    xs={6}
                    key={product.product_id}
                  >
                    <Card
                      updateProducts={updateProducts}
                      onRemove={removeProduct}
                      userId={appState.userId}
                      productInfo={product}
                      shareable
                    />
                  </Grid>
                )
              })
            )}
          </Grid>
          <div className={classes.paginationContainer}>
            <CHRArrowButton
              isLeftArrow
              label={t('shared.previous')}
              onClick={fetchPreviousPage}
              disabled={pageNumber === 1 || !productsCount}
            />
            <Typography variant="subtitle2" component="p">
              {pageNumber}/{Math.ceil(productsCount / pageSize) || 1}
            </Typography>
            <CHRArrowButton
              label={t('shared.next')}
              onClick={fetchNextPage}
              disabled={
                pageNumber === Math.ceil(productsCount / pageSize) ||
                !productsCount
              }
            />
          </div>
        </div>
      </div>
      {/* Pro section */}
      <CHRSectionContainer>
        <section>
          <MiddleSection
            handleArrowClick={() => navigate(ROUTES.chirpyestPro)}
            image={chirpyestProImg}
            buttonLabel={t('memberHome.learnMore')}
            rightChildren={
              <div>
                <Typography variant="h3">{t('memberHome.proTitle')}</Typography>
                <Typography variant="h2">
                  {t('memberHome.proSubtitle')}
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {t('memberHome.proText')}
                </Typography>
              </div>
            }
          />
        </section>
      </CHRSectionContainer>
    </Layout>
  )
}

export default withTranslation()(CategoryListingPage)

export const query = graphql`
  query {
    allImageSharp(
      filter: { fluid: { originalName: { in: ["getting-hang-of-it.png"] } } }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
